@import "../assets/utils";

/* Buttons */
button {
  border: 0;
}
.button:focus {
  outline: 0;
}

.button:disabled {
  color: #afafaf;
  background-color: #ebebeb;
}

.secondary-button:hover {
  color: #e5f1f4;
  background-color: #027d93;
}

button.loading {
  background: url(../assets/spinner.svg) no-repeat left center;
}

@media only screen and (max-width: 600px) {

  /* Buttons */
  .button {
    position: absolute;
    margin-top: get-vh(392px);
    width: get-vw(342px);
    height: get-vh(48px);
    border-radius: get-vh(24px);
    border: 0;
    padding: 0;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-vw(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }

  .secondary-button {
    min-width: max-content;    
    border-radius: get-vw(16px);
    padding: get-vh(5px) get-vw(15px) get-vh(5px) get-vw(15px);
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);
    color: #027d93;
    text-align: center;
    background-color: #e5f1f4;
    cursor: pointer;
  }

  button label {
    height: get-vh(18px);
    vertical-align: middle;
    cursor: pointer;
  }

  button img.icon-loading {  
    margin-right: get-vw(12px);
    margin: 0 get-vw(12px) 0 0;
    vertical-align: middle;
    -webkit-animation:spin 1.5s linear infinite;
    -moz-animation:spin 1.5s linear infinite;
    animation:spin 1.5s linear infinite;
  }
}

@media only screen and (min-width: 600px) {

  /* Buttons */
  .button {
    position: absolute;
    width: getd-vw(342px);
    height: getd-vh(48px);
    border-radius: getd-vw(24px);
    border: 0;
    padding: 0;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .secondary-button {
    min-width: max-content;
    height: fit-content; 
    border-radius: getd-vw(16px);
    padding: getd-vh(5px) getd-vw(15px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    text-align: center;
    color: #027d93;
    background-color: #e5f1f4;
    cursor: pointer;
  }

  button label {
    height: getd-vh(20px);
    vertical-align: middle;
    cursor: pointer;
  }

  button img.icon-loading {  
    margin-right: getd-vw(12px);
    height: getd-vh(20px);
    margin: 0 getd-vw(12px) 0 0;
    vertical-align: middle;
    -webkit-animation:spin 1.5s linear infinite;
    -moz-animation:spin 1.5s linear infinite;
    animation:spin 1.5s linear infinite;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .home-menu {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #ffffffee;
    z-index: 5;
    backdrop-filter: blur(10px);
    text-align: center;
  }

  .home-menu-left {
    position: absolute;
    height: get-vh(76px);
    text-align: left;
    padding: 0 !important;
  }

  .home-menu-logo {
    margin-top: get-vh(16px);
    margin-left: get-vw(16px);
    width: get-vh(40px);
  }

  .home-menu-right {  
    position: absolute;
    height: get-vh(76px);
    width: 100vw;
    text-align: right;
    padding: 0 !important;
  }

  .home-menu-close {
    vertical-align: top;
    margin-top: get-vh(24px);
    margin-right: get-vw(16px);
    width: get-vw(24px);
  }

  .home-menu-home {
    margin-top: get-vh(135px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(30px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(1.06px);
    text-align: center;
    color: #e60000;
  }

  .home-menu-mobile {
    margin-top: get-vh(24px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(30px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(1.06px);
    text-align: center;
    color: #222222;
  }

  .home-menu-tickets {
    margin-top: get-vh(24px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(30px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(1.06px);
    text-align: center;
    color: #222222;
  }

  .home-menu-logout {
    margin-top: get-vh(130px);
    margin-right: auto !important;
    margin-left: auto !important;
    width: fit-content !important;
    border-radius: get-vh(20px);
    padding: get-vh(7px) get-vw(10px) get-vh(5px) get-vw(10px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    letter-spacing: get-vw(0.56px);
    color: #007c92;
    text-align: right;
    background-color: rgba(0, 124, 146, 0.1);
  }

  .home-menu-logout-items {
    float: left;
  }

  .home-menu-language {
    margin-top: get-vh(48px);
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.64px);
    color: #007c92;

    /* styling */
    border: 0px solid black;
    display: inline-block;
    padding: 1.4vh 8.5vw 0.5vh 3.5vw;
    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, #007c92 50%), linear-gradient(135deg, #007c92 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    background-color: transparent;
    border-color: grey;
    outline: 0;
  }

  .home-menu-pp {
    margin-top: get-vh(20px);
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: center;
    color: #222222;
  }

  .home-menu-tc {
    margin-top: get-vh(14px);
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: center;
    color: #222222;
  }

  .home-menu-hf {
    margin-top: get-vh(14px);
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: center;
    color: #222222;
  }

  .home-menu-logout-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000aa;
    z-index: 1;
  }
  .home-logout-container{
    position: absolute;
    margin-top: get-vh(180px);
    margin-left: get-vw(32px);
    width: get-vw(326px) !important;
    height: get-vh(278px);
    border-radius: get-vw(24px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  .home-logout-title{
    position: absolute;
    margin-top: get-vh(32px);
    font-family: "VodafoneRgBd";
    font-size: get-font(24px);  
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing:get-vw(0.85px);
    text-align: center;
    color: #222222;
  }
  .home-logout-text{
    position: absolute;
    margin-top: get-vh(90px);
    font-family: "VodafoneRg";
    font-size: get-font(16px);  
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  .home-logout-button-logout{
    position: absolute;
    margin-top: get-vh(158px);
    margin-left: get-vw(24px);
    width: get-vw(278px) !important;
    padding: get-vh(11px) 0vw get-vh(11px) 0vw;
    border-radius: get-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .home-logout-button-logout:focus {
    outline: 0;
  }
  .home-logout-button-logout:disabled {
    background-color: #ebebeb;
  }

  .home-logout-button-cancel{
    position: absolute;
    margin-top: get-vh(214px);
    margin-left: get-vw(24px);
    width: get-vw(278px) !important;
    padding: get-vh(11px) 0vw get-vh(11px) 0vw;
    border-radius: get-vh(20px);
    border: get-vw(1px) solid #222222;
    background-color: #ffffff;
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }
  .home-logout-button-cancel:focus {
    outline: 0;
  }
  .home-logout-button-cancel:disabled {
    background-color: #ebebeb;
  }   

}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .rightSideImage {
    margin-top: 2%;
    width: 80%;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .login-logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: get-vh(40px);
    width: get-vw(64px);
  }

  .login-title {
    position: absolute;
    margin-top: get-vh(119px);
    font-size: get-vw(19.2px);
    font-family: "VodafoneRgBd";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.174vw;
    text-align: center;
    color: #222222;
  }

  .login-text {
    position: absolute;
    margin-top: get-vh(174px);    
    width: get-vw(342px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .login-labelTop {
    position: absolute;
    margin-top: get-vh(272px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-phoneNumber-InputContainer {
    position: absolute;
    margin-top: get-vh(295.5px);
  }

  .login-phoneNumber-labelInput {
    position: absolute;
    margin: get-vh(20px) 0vw 0vh get-vw(20px);
    font-family: "VodafoneRg";
    font-size: 2.37vh;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.182vw;
    color: #cccccc;
    z-index: 2;
  }

  .login-phoneNumber {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    position: relative;
    width: get-vw(342px);
    padding: 0 0 0 get-vw(80px);
    border-radius: get-vh(24px);
    border: 0px solid black;
    box-shadow: 0 0.237vw 0.948vh 0 rgba(0, 0, 0, 0.16);
    background: url(./../../assets/258.png) no-repeat left center;
    background-size: get-vw(70px);
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: 2.37vh;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(12px);
    color: #222222;
  }

  .login-labelBottom {
    position: absolute;
    margin-top: get-vh(352px);
    font-family: "VodafoneRg";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-confirm {
    margin-top: get-vh(392px);
  }

  .login-changePhoneNumber {
    position: absolute !important;
    margin-top: get-vh(456px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;    
    line-height: normal;
    text-align: center;
    color: #007c92;
  }

  .login-language {
    position: absolute;
    margin-top: get-vh(551px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.126vw;
    color: #007c92;

    /* styling */
    border: 0;
    padding: 0 get-vw(18px);

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url(../../assets/icons-chevron-down-fill.svg);
    background-position: center right;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .login-copyright {
    position: absolute !important;
    margin-top: get-vh(591px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }

  .login-tcppf {
    position: absolute !important;
    margin-top: get-vh(613px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }

  .login-recaptcha {
    position: absolute !important;
    margin-top: get-vh(631px);
    margin-left: get-vw(20px);
    margin-right: get-vw(20px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }

  .login-blocked-resendtext {    
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #e60000;
  }
}

@media only screen and (min-width: 600px) {
  .leftSide {
    width: 50% !important;
    height: getd-vh(900px);
  }

  .rightSide {
    width: 50% !important;
    height: getd-vh(900px);
    background: url(./../../assets/rightImage.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .login-logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: getd-vh(135px);
    width: getd-vh(92px);
    height: getd-vh(92px);
  }

  .login-title {
    position: absolute;
    margin-top: getd-vh(249px);
    font-size: getd-font(24px);
    font-family: "VodafoneRgBd";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.85px);
    text-align: center;
    color: #222222;
  }

  .login-text {
    position: absolute;
    margin-top: getd-vh(349px);
    width: getd-vw(342px);   
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .login-labelTop {
    position: absolute;
    margin-top: getd-vh(467px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-phoneNumber-InputContainer {
    position: absolute;
    margin-top: getd-vh(491px);
  }

  .login-phoneNumber {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    position: relative;
    width: getd-vw(342px);
    padding: 0 0 0 getd-vw(80px);    
    border-radius: getd-vw(24px);
    border: 0px solid black;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background: url(./../../assets/258.png) no-repeat left center;
    background-size: getd-vw(70px);
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(12px);
    color: #222222;
  }

  .login-labelBottom {
    position: absolute;
    margin-top: getd-vh(548px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-confirm {
    margin-top: getd-vh(628px);
  }

  .login-changePhoneNumber {
    position: absolute !important;
    margin-top: getd-vh(685px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #007c92;
    cursor: pointer;
  }

  .login-language {
    position: absolute;
    margin-top: getd-vh(732px);
    font-family: "VodafoneRg";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.64px);
    color: #007c92;

    /* styling */
    border: 0;
    width: getd-vw(105px);
    padding: 0;

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url(../../assets/icons-chevron-down-fill.svg);
    background-position: center right;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  
  .login-language option {
    width: getd-vw(70.4px);
  }

  .login-copyright {
    position: absolute !important;
    margin-top: getd-vh(810px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
  }

  .login-tcppf {
    position: absolute !important;
    margin-top: getd-vh(832px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
  }

  .login-recaptcha {
    position: absolute !important;
    margin-top: getd-vh(854px);
    margin-bottom: getd-vh(54px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
  }

  .login-blocked-timer {
    position: absolute;    
    margin-top: getd-vh(500px);
    font-family: "VodafoneRgBd";
    font-size: getd-vw(50px);
    font-stretch: normal;
    font-style: normal;
    line-height: getd-vh(15px);
    text-align: center;
    color: #222222;
  }

  .login-blocked-resendtext {    
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: getd-vh(15px);
    text-align: center;
    color: #e60000;
  }
}

@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .categories-list-container{
    margin-top: get-vh(24px);
    margin-left: get-vw(24px);
    margin-right: get-vw(24px);
    text-align: left;
    width: 100%;
    
  }

  .categories-list-title-data{
    font-family: "VodafoneRg";
    font-size: get-font(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }

  .categories-list-title-home {
    font-family: "VodafoneRgBd";
    font-size: get-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.71px);
    color: #222222;
    text-align: left;
    padding-bottom: get-vh(12px);
  }

  .categories-list-item{
    margin-top: get-vh(12px);
    margin-bottom: get-vh(22px);
  }

  .categories-list-item-name-limit{
    width: 100%;
  }

  .categories-list-item-name{
    float: left;
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    color: #222222;
  }

  .categories-list-item-limit{
    float: right;
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    color: #222222;
  }

  .categories-list-item-limit-text{
    font-size: get-font(14px);
  }

  .categories-list-item-line{
    clear: both;
    padding-top:  get-vh(8px);
  }
}

@media only screen and (min-width: 600px) {
  .categories-list-container{
    margin-top: getd-vh(24px);
    margin-left: getd-vw(24px);
    margin-right: getd-vw(24px);
    text-align: left;
    width: 100%;
    
  }

  .categories-list-title-data{
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
  }

  .categories-list-title-home {
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.71px);
    color: #222222;
    text-align: left;
    padding-bottom: getd-vh(12px);
  }

  .categories-list-item{
    margin-top: getd-vh(12px);
    margin-bottom: getd-vh(22px);
  }

  .categories-list-item-name-limit{
    width: 100%;
  }

  .categories-list-item-name{
    float: left;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #222222;
  }

  .categories-list-item-limit{
    float: right;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #222222;
  }

  .categories-list-item-limit-text{
    font-size: getd-font(14px);
  }

  .categories-list-item-line{
    clear: both;
    padding-top:  getd-vh(8px);
  }
}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  
  .newTicketEmailDataAccess-labelTop {
    margin-top: get-vh(86px);
  }
  
  .newTicketEmailDataAccess-email {
    margin-top: get-vh(110px);
  }

  .newTicketEmailDataAccess-labelBottom {
    margin-top: get-vh(166px);
  }

  .newTicket-continueButton, .newTicket-sendButton {
    margin-top: get-vh(459px);
  }
}

@media only screen and (min-width: 600px) {
  
  .newTicketEmailDataAccess-email {
    width: getd-vw(395px);
  }
}


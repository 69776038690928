@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .tickets-container{
    width: 100%;
    margin-left: get-vw(24px);
    margin-right: get-vw(24px);
  }

  .tickets-item-container {
    margin-top: get-vh(11px);
    display: flex;
    width: 100%;
    margin-bottom: get-vh(9px);
  }
  .tickets-items-left {
    flex: 1;
  }
  .tickets-items-right {
    flex: 1;
  }

  .tickets-item-text {
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    color: #222222;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: get-vw(171px);
  }

  .tickets-item-subtext {
    margin-top: get-vh(6px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    text-align: left;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: get-vw(171px);    
  }
  
  .tickets-item-type {
    font-family: "VodafoneRgBd";
    font-size: get-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    color: #222222;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: get-vw(171px);
  }
 
  .tickets-item-status {
    margin-top: get-vh(6px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    text-align: right;
    color: #222222;
  }
  .tickets-item-status-open {    
    color: #007c92;
  }
  .tickets-item-status-inreview {    
    color: #eb9700;
  }
  .tickets-item-status-canceled {    
    color: #e60000;
  }
  .tickets-item-status-closed {    
    color: #999999;
  }
  .tickets-item-status-new {    
    color: #007c92;
  }

  .tickets-item-container.loading {
    margin-bottom: get-vh(24px);
  }

  .tickets-item-text.loading-placeholder {
    float: left;
    padding-right: get-vw(74px);
    height: get-vh(14px);
  }

  .tickets-item-subtext.loading-placeholder-secondary {
    float: left;
    clear: both;
    padding-right: get-vw(172px);
    height: get-vh(12px);
    margin-top: get-vh(8px);
  }

  .tickets-item-type.loading-placeholder {
    float: right;
    padding-right: get-vw(52px);
    height: get-vh(14px);
  }
}


@media only screen and (min-width: 600px) {

  .tickets-container{
    width: getd-vw(524px);
    margin-left: getd-vw(24px);
    margin-right: getd-vw(24px);
  }

  .tickets-item-container {
    margin-top: getd-vh(11px);
    display: flex;
    width: 100%;
    margin-bottom: getd-vh(9px);
    cursor: pointer;
  }
  .tickets-items-left {
    flex: 1;
  }
  .tickets-items-right {
    flex: 1;
  }

  .tickets-item-text {
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #222222;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: getd-vw(350px);
  }

  .tickets-item-subtext {
    margin-top: getd-vh(6px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    text-align: left;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: getd-vw(171px);    
  }
  
  .tickets-item-type {
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    color: #222222;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }
 
  .tickets-item-status {
    margin-top: getd-vh(6px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    text-align: right;
    color: #222222;
  }
  .tickets-item-status-new {    
    color: #007c92;
  }
  .tickets-item-status-open {    
    color: #007c92;
  }
  .tickets-item-status-inreview {    
    color: #eb9700;
  }
  .tickets-item-status-canceled {    
    color: #e60000;
  }
  .tickets-item-status-closed {    
    color: #999999;
  }

  .tickets-item-container.loading {
    margin-bottom: getd-vh(24px);
  }

  .tickets-item-text.loading-placeholder {
    float: left;
    padding-right: getd-vw(74px);
    height: getd-vh(14px);
  }

  .tickets-item-subtext.loading-placeholder-secondary {
    float: left;
    clear: both;
    padding-right: getd-vw(172px);
    height: getd-vh(12px);
    margin-top: getd-vh(8px);
  }

  .tickets-item-type.loading-placeholder {
    float: right;
    padding-right: getd-vw(52px);
    height: getd-vh(14px);
  }
}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .login-otp-labelTopError {
    position: absolute;
    margin-top: get-vh(272px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #e60000;
  }

  .login-otp-inputContainer {
    position: absolute;
    margin-top: get-vh(296px);
  }

  .login-otp {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    position: relative;
    width: get-vw(342px);
    padding-left: get-vw(45px);
    border-radius: get-vh(24px);
    border: 0px solid black;
    box-shadow: 0 0.237vw 0.948vh 0 rgba(0, 0, 0, 0.16);    
    background-size: get-vw(70px);
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: 2.37vh;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(38px);
    border: none;
    display: inline-block;
    text-shadow: 0 0 0 #222222;    
  }

  .login-otpError {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    position: relative;
    width: get-vw(342px);
    height: 5.687vh;
    padding: 1.422vh 4.103vw 1.422vh 9.103vw;
    border-radius: get-vh(24px);
    border: 1px solid #e60000;
    box-shadow: 0 0.237vw 0.948vh 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: 2.37vh;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(38px);
    display: inline-block;
    text-shadow: 0 0 0 #222222;
  }

  .login-otp-labelBottomP2 {
    font-family: "VodafoneRg";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;    
    line-height: normal;
    text-align: center;
    color: #007c92;
  }
    
}


@media only screen and (min-width: 600px) {

  .login-otp-labelTopError {
    position: absolute;
    margin-top: getd-vh(469px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #e60000;
  }

  .login-otp-inputContainer {
    position: absolute;
    margin-top: getd-vh(491px);
  }

  .login-otp {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    -webkit-appearance: none;
    position: relative;
    width: getd-vw(342px);
    height: getd-vh(48px);
    padding-left: getd-vw(40px);
    border-radius: getd-vw(24px);
    border: 0px solid black;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(40px);
    border: none;
    display: inline-block;
    text-shadow: 0 0 0 #222222;
  }

  .login-otpError {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    position: relative;
    width: getd-vw(342px);
    height: getd-vh(48px);
    padding-left: getd-vh(40px);
    border-radius: getd-vh(24px);
    border: 1px solid #e60000;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(11px);
    display: inline-block;
    text-shadow: 0 0 0 #222222;
  }

  .login-otp-labelBottomP2 {  
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;    
    line-height: normal;
    text-align: center;    
    color: #007c92;
    cursor: pointer;
  }
}

@import "../../assets/utils";


@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .data-line-graph{
    margin-top: get-vw(24px);
    margin-bottom: get-vw(24px);
    margin-left: get-vw(24px);
    width: get-vw(342px);    
    border-radius: get-vw(15px);
    box-shadow: 0 get-vw(2px) get-vw(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .data-line-graph-dates{
    float: left;
    margin-top: get-vh(16px);
    margin-left: get-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: get-font(20px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.71px);
    color: #222222;
    text-align: left;
  }

  .data-line-graph-dates.loading-placeholder {
    padding-right: get-vw(105px);
    height: get-vh(16px);
    margin-top: get-vh(45px);
    margin-left: get-vw(24px);
  }

  .data-line-graph-singledate{
    margin-top: get-vh(12px);
    float:right;
  }
  .data-line-graph-singledate-icon-container{
    width: get-vw(28px);
    height: get-vw(28px);
    border-radius: get-vw(16px);
    background-color: #e5f1f4; //#007c92;
    margin-right: get-vw(12px);
  }
  .data-line-graph-singledate-icon-container-picked{
    width: get-vw(28px);
    height: get-vw(28px);
    border-radius: get-vw(16px);
    background-color: #007c92;
    margin-right: get-vw(12px);
  }
  .data-line-graph-singledate-icon, .data-line-graph-singledate-icon-picked{
    width: get-vw(16px);
    height: get-vh(16px);
    vertical-align: middle;
    cursor: pointer;
  }

  .data-line-graph-type{
    clear: both;
    margin-top: get-vh(44px);
    margin-left: get-vw(24px);
    font-family: "VodafoneRg";
    font-size: get-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.42px);
    color: #999999;
    text-align: left;
  }
  .data-line-graph-type.loading-placeholder {
    padding-right: get-vw(70px);
    height: get-vh(8px);
    margin-top: get-vh(8px);
    margin-left: get-vw(24px);
  }
  .data-line-graph-total{    
    float: left;
    text-align: left;
    margin-left: get-vw(16px);
    margin-bottom: get-vh(24px);
  }
  .data-line-graph-total-bullet{
    font-size: get-font(40px);
    color: #e60000;
  }
  .data-line-graph-daily{    
    float: left;
    text-align: left;
    margin-left: get-vw(20px);
    margin-bottom: get-vh(24px);
  }
  .data-line-graph-daily-bullet{
    font-size: get-font(40px);
    color: #007c92;
  }
  .data-line-graph-td-title{
    margin-top: get-vh(-10px);
    font-family: "VodafoneRg";
    font-size: get-font(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }
  .data-line-graph-td-spent {
    margin-top: get-vh(10px);
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    color: #222222;
  }

  .data-line-graph-empty {
    float: left;
    width: 85%;
    margin-top: get-vh(180px);
    margin-left: get-vw(24px);
    border-top: 1px solid #ebebeb;
  }
}

@import "../../assets/utils";

@media only screen and (min-width: 600px) {


  .support-data-container{
    width: 100%;  
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .support-data-title{
    width: getd-vw(524px) !important;
    margin-top: getd-vh(46px);
    margin-left: getd-vw(8px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(32px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
  }

  .support-data-datePicker{
    margin-top: getd-vh(30px);
    margin-left: getd-vw(8px);
  }

  .support-data-statusPicker{    
    margin-top: getd-vh(32px);  
  }
  
  .support-data-rangue{
    margin-top: getd-vh(20px) !important; 
    width: getd-vw(524px) !important;
  }

  .support-data-rangue .empty-data .icon-empty-box { 
    margin-top: getd-vh(51px);
  }

  .data-line-graph{
    margin-top: getd-vw(24px);
    margin-bottom: getd-vw(24px);
    margin-left: getd-vw(8px);
    width: getd-vw(524px);
    border-radius: getd-vw(15px);
    box-shadow: 0 getd-vw(2px) getd-vw(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .data-line-graph-dates{
    float: left;
    margin-top: getd-vh(16px);
    margin-left: getd-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.71px);
    color: #222222;
    text-align: left;
  }
  
  .data-line-graph-dates.loading-placeholder {
    padding-right: getd-vw(105px);
    height: getd-vh(16px);
    margin-top: getd-vh(45px);
    margin-left: getd-vw(24px);
  }

  .data-line-graph-singledate{
    margin-top: getd-vh(12px);
    float:right;
  }
  .data-line-graph-singledate-icon-container{
    width: getd-vw(28px);
    height: getd-vw(28px);
    border-radius: getd-vw(16px);
    background-color: #e5f1f4; //#007c92;
    margin-right: getd-vw(12px);
    cursor: pointer;
  }
  .data-line-graph-singledate-icon-container-picked{
    width: getd-vw(28px);
    height: getd-vw(28px);
    border-radius: getd-vw(16px);
    background-color: #007c92;
    margin-right: getd-vw(12px);
    cursor: pointer;
  }
  .data-line-graph-singledate-icon, .data-line-graph-singledate-icon-picked {
    margin-top: getd-vw(6px);
    width: getd-vw(16px);
    height: getd-vw(16px);
    cursor: pointer;
  }

  .data-line-graph-type{
    clear: both;
    margin-top: getd-vh(44px);
    margin-left: getd-vw(24px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
    text-align: left;
  }
  .data-line-graph-type.loading-placeholder {
    padding-right: getd-vw(70px);
    height: getd-vh(8px);
    margin-top: getd-vh(8px);
    margin-left: getd-vw(24px);
  }
  .data-line-graph-total{    
    float: left;
    text-align: left;
    margin-left: getd-vw(16px);
    margin-bottom: getd-vw(24px);
  }
  .data-line-graph-total-bullet{
    font-size: getd-font(40px);
    color: #e60000;
  }
  .data-line-graph-daily{    
    float: left;
    text-align: left;
    margin-left: getd-vw(16px);
    margin-bottom: getd-vw(24px);
  }
  .data-line-graph-daily-bullet{
    font-size: getd-font(40px);
    color: #007c92;
  }
  .data-line-graph-td-title{
    margin-top: getd-vh(-10px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.42px);
    color: #999999;
  }
  .data-line-graph-td-spent {
    margin-top: getd-vh(10px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #222222;
  }
  
  .data-line-graph-empty {
    float: left;
    width: 85%;
    margin-top: getd-vh(280px);
    margin-left: getd-vw(24px);
    border-top: 1px solid #ebebeb;
  }

  .support-data-categories{
    margin-top: getd-vh(40px);
    margin-left: getd-vw(-40px);
    width: getd-vw(524px) !important;
    padding-bottom: getd-vh(40px);
  }
}

.data-line-graph-loading-bullet {
  color: #999;
  opacity: 0.3;
}
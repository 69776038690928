@import "../../assets/utils";

.ticket-detail-container{
  width: get-vw(342px) !important;
  height: get-vh(330px);
  margin: get-vh(0px) get-vw(24px) 0px get-vw(24px);  
  border-radius: get-vw(16px);
  box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
  padding-bottom: get-vh(26px);
  overflow-x: hidden;
  overflow-y: auto;
}

.ticket-detail-name{  
  margin-top: get-vh(26px);
  margin-left: get-vw(24px);
  font-family: "VodafoneRg";
  font-size: get-font(14px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: get-vw(0.49px);
  color: #999999;
}

.ticket-detail-value{
  width: get-vw(300px) !important;
  margin-top: get-vh(6px);
  margin-bottom: get-vh(2px);
  margin-left: get-vw(24px);
  font-family: "VodafoneRgBd";
  font-size: get-font(16px);  
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: get-vw(0.56px);
  color: #222222;
  text-align: left;
}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .rangue-type-container{
    margin-top: get-vh(10px);
    width: get-vw(193px);
    background-color: #2222224c;
    margin-left: auto;
    margin-right: auto;
    border-radius: get-vw(9px);
  }

  .rangue-type-item-selected{
    float:left;
    width: get-vw(75px);
    border-radius: get-vw(9px);
    background-color: #ffffff;
    border: 1px solid #2222224c;
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.49px;
    text-align: center;
    color: #222222;
    padding: get-vh(8px) get-vw(10px) get-vh(8px) get-vw(10px);
    cursor: pointer;
  }

  .rangue-type-item{
    float:left;
    width: get-vw(75px);
    border-radius: get-vw(9px);
    background-color: transparent;
    border: 0px solid #2222224c;
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.49px;
    text-align: center;
    color: #222222;
    padding: get-vh(8px) get-vw(10px) get-vh(8px) get-vw(10px);
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) {

  .rangue-type-container{
    margin-top: getd-vh(10px);
    width: getd-vw(162px);    
    background-color: #2222224c;
    margin-left: auto;
    margin-right: auto;
    border-radius: getd-vw(9px);
    display: flex;;
  }
  
  .rangue-type-item-selected{
    position: relative;
    flex: 1;
    width: getd-vw(60px);
    top: getd-vh(0px);
    left: getd-vw(0px);
    border-radius: getd-vw(9px);
    background-color: #ffffff;
    border: 1px solid #2222224c;
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.49px;
    text-align: center;
    color: #222222;
    padding: getd-vh(8px) getd-vw(10px) getd-vh(8px) getd-vw(10px);
    cursor: pointer;
  }
  
  .rangue-type-item{
    position: relative;
    flex: 1;
    width: getd-vw(60px);
    top: getd-vh(0px);
    left: getd-vw(0px);
    border-radius: getd-vw(9px);
    background-color: transparent;
    border: 0px solid #2222224c;
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.49px;
    text-align: center;
    color: #222222;
    padding: getd-vh(8px) getd-vw(10px) getd-vh(8px) getd-vw(10px);
    cursor: pointer;
  }
}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {

.data-date-picker {
    margin-top: get-vh(100px);    
    margin-left: get-vw(24px) !important;   
    margin-right: get-vw(24px) !important;   
    width: get-vw(342px);
    border-radius: get-vw(15px);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
  }
  .date-picker-left{
    flex: 3;
    text-align: left;
    margin-top: get-vh(10px);
    margin-left: get-vw(10px);
    padding-top: get-vh(2px);
    padding-bottom: get-vh(5px);
  }
  .date-picker-middle{
    flex: 1;
    display: contents;
    text-align: center;   
    
  }
  .date-picker-right{
    flex: 3;
    text-align: right;
    margin-top: get-vh(10px);
    margin-right: get-vw(10px);
    padding-top: get-vh(2px);
    padding-bottom: get-vh(5px);
  }
  .date-picker-arrow {
    width: get-vw(16px);
    height: get-vh(50px);
    margin: auto;
    color: #afafaf;
    transform: scale(1.5, 3);
  }  
  .date-picker-title{
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    color: #999999;    
  }
  .data-date-datePicker{
    width: get-vw(150px);
    border: 0 solid black;
    background-color: transparent;
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    color: #222222;
    outline: 0;
  }  
  @supports (-webkit-touch-callout: none) {
    .data-date-datePicker{
      margin-right: get-vw(-10px);
    }
  }
  .data-date-datePicker:focus{
    color: #ff0022;    
  }

  .data-date-datePicker-left{
    text-align: left;
    padding-left: 0;
  }
  .data-date-datePicker-right{
    text-align: right;
  }
  








  .react-datepicker{
    border: 0px solid black;
    border-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  .react-datepicker__triangle{
    display: none;
  }  
  .react-datepicker__day-name{
    width: get-vw(32px);
    height: get-vh(32px);
    font-family: "VodafoneRgBd";
    font-size: get-font(14px);
    color: #222222;
  }
  .react-datepicker__day{
    width: get-vw(30px);
    height: get-vw(28px);
    padding-top: get-vh(2px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);    
  }
  .react-datepicker__day--selected{
    border-radius: 50%;
    background-color: #e60000;
    color: #fff;
  }
  .react-datepicker__day--today{   
    border-radius: 50%;
    background-color: #999999;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #ffffff;
    color: #222222;
  }
  .react-datepicker__header{
    background-color: transparent;
    border-bottom: 0px solid black;
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }

  .react-datepicker__navigation--next {
    right: 35px;    
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .react-datepicker__navigation--previous {
    left: 35px;    
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}













  @media only screen and (min-width: 600px) {

  .data-date-picker {   
    width: getd-vw(524px);
    display: flex;
  }

  .date-picker-left, .date-picker-right {
    flex: 10;
    text-align: left;
    padding: getd-vh(10px) getd-vw(16px);
    border-radius: getd-vw(15px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    height: fit-content;
    cursor: pointer;
  }

  .date-picker-left {
    text-align: left;
  }
  
  .date-picker-right {
    text-align: right;
  }

  .date-picker-middle {
    flex: 3;
    text-align: center;
    height: getd-vh(56px); 
  }
    
  .date-picker-arrow{
    margin: auto;
    transform: scale(0.25, 0.35);
    color: #afafaf;
  }  
  .date-picker-title{
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    color: #999999;
  }
  .data-date-datePicker {
    width: getd-vw(180px);
    border: 0 solid black;
    background-color: transparent;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;    
    letter-spacing: getd-vw(0.64px);
    color: #222222;
    outline: 0;
    cursor: pointer;
  }
  .data-date-datePicker:focus{
    color: #ff0022;    
  }

  .data-date-datePicker-left{
    text-align: left;
    padding-left: 0;
  }
  .data-date-datePicker-right{
    text-align: right;
  }
  


  .react-datepicker{
    border: 0px solid black;
    border-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  .react-datepicker__triangle{
    display: none;
  }  
  .react-datepicker__day-name{
    width: getd-vw(32px);
    height: getd-vh(32px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    color: #222222;
  }
  .react-datepicker__day{
    width: getd-vh(35px);
    height: getd-vh(30px);
    padding-top: getd-vh(5px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);    
  }
  .react-datepicker__day--selected{
    border-radius: 50%;
    background-color: #e60000;
    color: #fff;
  }
  .react-datepicker__day--today{
    border-radius: 50%;
    background-color: #999999;
  }

  .react-datepicker__header{
    background-color: transparent;
    border-bottom: 0px solid black;
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }

  .react-datepicker__navigation {
    width: 15px;
    height: 15px;
    margin-top: 10px;
  }

  .react-datepicker__navigation--next {
    right: 35px;    
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .react-datepicker__navigation--previous {
    left: 35px;    
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
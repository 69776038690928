@import "assets/utils";

.App {
  text-align: center;
}

@font-face {
  font-family: "VodafoneLt";
  font-style: normal;
  font-weight: 100;
  src: url("assets/fonts/vodafone/light/vodafone-light.woff2") format("woff2"), url("assets/fonts/vodafone/light/vodafone-light.woff") format("woff"),
    url("assets/fonts/vodafone/light/vodafone-light.ttf") format("truetype");
}
@font-face {
  font-family: "VodafoneRg";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/vodafone/regular/vodafone-regular.woff2") format("woff2"), url("assets/fonts/vodafone/regular/vodafone-regular.woff") format("woff"),
    url("assets/fonts/vodafone/regular/vodafone-regular.ttf") format("truetype");
}
@font-face {
  font-family: "VodafoneRgBd";
  font-style: bold;
  src: url("assets/fonts/vodafone/bold/vodafone-bold.woff2") format("woff2"), url("assets/fonts/vodafone/bold/vodafone-bold.woff") format("woff"),
    url("assets/fonts/vodafone/bold/vodafone-bold.ttf") format("truetype");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation */
@-webkit-keyframes ssc-loading {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes ssc-loading {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

/* Checkbox */
input[type="checkbox"]:checked {
  background-color: #e60000;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-placeholder {
  float: left;
  position: relative;
  opacity: 0.3;
  border-radius: 6px;
  background-color: #999;
}

.loading-placeholder-secondary {
  float: left;
  position: relative;
  opacity: 0.3;
  border-radius: 6px;
  background-color: #f4f4f4;
}

.loading-placeholder:after {
  content: "";
  -webkit-animation: ssc-loading 1.3s infinite;
  animation: ssc-loading 1.3s infinite;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1;
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, 0.3)), to(transparent));
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
}

@media only screen and (max-width: 600px) {

  input[type]:not([type='checkbox']):not(.data-date-datePicker) {
    height: get-vh(48px);
  }

  /* Checkbox */
  input[type="checkbox"] {  
    -webkit-appearance: initial;
    appearance: initial;     
    width: get-vw(16px);
    height: get-vw(16px);
    margin-right: get-vw(17px);
    margin-top: 0;
    margin-bottom: 0;
    border-radius: get-vw(5px);
    border: solid 1px #222;
  }
  
  input[type="checkbox"]:checked:after {
    /* Heres your symbol replacement - this is a tick in Unicode. */
    content: "\2713";
    color: #fff;
    /* The following positions my tick in the center, 
    * but you could just overlay the entire box
    * with a full after element with a background if you want to */
    margin-left: get-vw(3px);
    -webkit-transform: translate(-50%,-50%) scale(0.75, 0.75);
    -moz-transform: translate(-50%,-50%) scale(0.75, 0.75);
    -ms-transform: translate(-50%,-50%) scale(0.75, 0.75);
    transform: translate(-50%,-50%) scale(0.75, 0.75);    
    /*
    * If you want to fully change the check appearance, use the following:
    * content: " ";
    * width: 100%;
    * height: 100%;
    * background: blue;
    * top: 0;
    * left: 0;
    */
  }

  /* Select */
  .css-2b097c-container {
    padding: get-vh(0px) get-vw(18px);
  }

  .select__value-container {
    padding: get-vh(10px) get-vw(0px) !important;
  }

  .select__menu{
    width: get-vw(342px);    
    margin-left: get-vw(-18px);
    padding: get-vh(0px) get-vw(0px) !important;
    border-radius: get-vw(24px) !important;
    border: 0px solid black !important;
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
  }

  .select__menu-list{        
    width: get-vw(342px);
    border-radius: get-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: left;
    padding: 0px 0px 0px get-vw(8px);
  }
  
  /* Content Card */
  .content-card {
    margin-bottom: get-vh(16px);
    width: get-vw(480px);
    height: get-vh(30px);
    padding: get-vh(35px);
    border-radius: get-vw(12px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  .content-card-item-logo {
    float: left;
    width: get-vw(20px);
    height: get-vw(20px);
    margin-right: get-vw(16px);
  }

  .content-card-item-label {
    float: left;
    margin-top: get-vh(1px);
    font-family: "VodafoneRgBd";
    font-size: get-font(20px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
  }

  .content-card-item-action {  
    float: right;
    width: getm-vw(26px);
    height: getm-vh(32px);
    border-radius: getm-vw(16px);
    background-color: rgba(0, 124, 146, 0.1);    
    cursor: pointer;
  }

  .content-card-item-action img {    
    width: getm-vw(13px);
    height: getm-vw(13px);
    padding-top: getm-vh(8px);
  }

  .react-datepicker__navigation{
    width: get-vw(10px);
    height: get-vw(10px);
    margin-top: get-vh(8px);
  }
}

@media only screen and (min-width: 600px) {

  input[type]:not([type='checkbox']):not(.data-date-datePicker) {
    height: getd-vh(48px);
  }
  
  /* Checkbox */
  input[type="checkbox"] {  
    -webkit-appearance: initial;
    appearance: initial;     
    width: getd-vw(16px);
    height: getd-vw(16px);
    margin-right: getd-vw(17px);
    margin-top: 0;
    margin-bottom: 0;
    border-radius: getd-vw(5px);
    border: solid 1px #222;
    cursor: pointer;
  }
  
  input[type="checkbox"]:checked:after {
    /* Heres your symbol replacement - this is a tick in Unicode. */
    content: "\2713";
    color: #fff;
    /* The following positions my tick in the center, 
    * but you could just overlay the entire box
    * with a full after element with a background if you want to */
    margin-left: getd-vw(4px);
    -webkit-transform: translate(-50%,-50%) scale(0.75, 0.75);
    -moz-transform: translate(-50%,-50%) scale(0.75, 0.75);
    -ms-transform: translate(-50%,-50%) scale(0.75, 0.75);
    transform: translate(-50%,-50%) scale(0.75, 0.75);    
    /*
    * If you want to fully change the check appearance, use the following:
    * content: " ";
    * width: 100%;
    * height: 100%;
    * background: blue;
    * top: 0;
    * left: 0;
    */
  }

  /* Select */
  .css-2b097c-container {
    position: absolute !important;
    margin-top: getd-vh(228px);
    width: getd-vw(400px);
    padding: getd-vh(0px) getd-vw(18px);
    border-radius: getd-vw(24px);
    border: 0px solid black !important;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    outline: none;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    text-align: left;  
  }

  .select__value-container {
    height: getd-vh(52px);
    padding: 0 !important;
    cursor: pointer;
  }
  
  .select__menu {
    width: getd-vw(400px);
    margin-left: get-vw(-5px);
    padding: getd-vh(0px) getd-vw(0px);
    border-radius: getd-vw(24px) !important;
    border: 0px solid black !important;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
  }

  .select__menu-list{        
    width: getd-vw(342px);
    border-radius: getd-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    text-align: left;
    padding: 0px 0px 0px getd-vw(8px);
  }

  /* Content Card */
  .content-card {
    margin-bottom: get-vh(16px);
    width: getd-vw(463px);
    height: getd-vh(30px);
    padding: getd-vh(30px);
    border-radius: getd-vw(12px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    cursor: pointer;
    margin-left: getd-vw(4px);
    margin-right: getd-vw(4px);
  }

  .content-card-item-logo {
    float: left;
    width: getd-vw(20px);
    height: getd-vw(20px);
    margin-right: getd-vw(16px);
  }
  
  .content-card-item-label {
    float: left;
    margin-top: getd-vh(1px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
  }

  .content-card-item-action {
    float: right;
    width: getd-vw(26px);
    height: getd-vh(32px);
    border-radius: getd-vw(16px);
    background-color: rgba(0, 124, 146, 0.1);
  }

  .content-card-item-action img {    
    width: getd-vw(13px);
    height: getd-vw(13px);
    padding-top: getd-vh(8px);
  }

  .react-datepicker__navigation{
    width: getd-vw(10px);
    height: getd-vw(10px);
  }
}
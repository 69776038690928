@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .rightSideImage {
    margin-top: 2%;
    width: 80%;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
  
  .login-phoneNumber-labelTop {
    position: absolute;
    margin-top: get-vh(272px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-phoneNumber-InputContainer {
    position: absolute;
    margin-top: get-vh(296px);
  }

  .login-phoneNumber-labelInput {
    position: absolute;
    margin: get-vh(20px) 0vw 0vh get-vw(20px);
    font-family: "VodafoneRg";
    font-size: 2.37vh;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.182vw;
    color: #cccccc;
    z-index: 2;
  }

  .login-phoneNumber-labelBottom {
    position: absolute;
    margin-top: get-vh(352px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .login-phoneNumber-confirm {
    position: absolute;
    margin-top: get-vh(392px);
    width: get-vw(342px);
    padding: get-vh(11px) 0vw get-vh(11px) 0vw;
    border-radius: get-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .login-phoneNumber-confirm:focus {
    outline: 0;
  }
  .login-phoneNumber-confirm:disabled {
    background-color: #ebebeb;
  }

  .login-phoneNumber-language {
    position: absolute;
    margin-top: get-vh(552px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.126vw;
    color: #007c92;

    /* styling */
    border: 0px solid black;
    display: inline-block;
    padding: 1.25vh 8.5vw 0.5vh 3.5vw;
    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, #007c92 50%), linear-gradient(135deg, #007c92 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    background-color: transparent;
    border-color: grey;
    outline: 0;
  }

  .login-phoneNumber-copyright {
    position: absolute !important;
    margin-top: get-vh(592px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }

  .login-phoneNumber-tcppf {
    position: absolute !important;
    margin-top: get-vh(614px);
    font-family: "VodafoneRg";
    font-size: get-vw(12px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.42px);
    color: #999999;
  }
}
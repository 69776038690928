@import "../../assets/utils";


.newticket-tc-agree-text a {
  color: #007c92;
}

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .newticket-tc-close{
    position: absolute;
    right: 0px;
  }

  .newticket-tc-close-img {
    vertical-align: top;
    margin-top: get-vh(24px);
    margin-right: get-vw(16px);
    width: get-vw(24px);
  }

  .newticket-tc-logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: get-vh(40px);
    width: get-vw(64px);
  }

  .newticket-tc-title {
    position: absolute;
    margin-top: get-vh(120px);
    font-size: get-vw(19.2px);
    font-family: "VodafoneRgBd";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.174vw;
    text-align: center;
    color: #222222;
  }


  .newticket-tc-text{
    position: absolute;
    margin-top: get-vh(174px);
    margin-left: get-vw(10px);
    margin-right: get-vw(10px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-tc-terms {
    position: absolute;
    margin-top: get-vh(215px);
    margin-left: get-vw(24px);
    margin-right: get-vw(24px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: get-vh(20px);
    text-align: left;
    max-height: get-vh(300px);
    overflow: auto;
    color: #222222;
  }

  @supports (-webkit-touch-callout: none) {
    .newticket-tc-terms {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: get-vw(24px);
      padding-right: get-vw(24px);
    }
  }



  .newticket-tc-pp {
    position: absolute;
    margin-top: get-vh(514px);
  }

  .newticket-tc-tc {
    position: absolute;
    margin-top: get-vh(548px);
  }

  .newticket-tc-agree-cb {
    width: get-vw(16px);
    height: get-vh(16px) !important;
    background-color: white;
    border-radius: 25%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .newticket-tc-agree-cb:checked {
    background-color: #e60000;
  }
  
  .newticket-tc-agree-text {
    margin-top: get-vh(4px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: get-vw(19px);
    text-align: left;
  }

  .newticket-tc-confirm {
    position: absolute;
    margin-top: get-vh(590px);
    width: get-vw(342px);
    padding: get-vh(10px) 0vw get-vh(10px) 0vw;
    border-radius: get-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .newticket-tc-confirm:focus {
    outline: 0;
  }
  .newticket-tc-confirm:disabled {
    background-color: #ebebeb;
  }
}

@media only screen and (min-width: 600px) {
  
  .newticket-tc-text{
    position: absolute;
    margin-top: getd-vh(51px);
    margin-left: getd-vw(10px);
    margin-right: getd-vw(10px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(24px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-tc-terms {
    position: absolute;
    margin-top: getd-vh(164px);
    margin-left: getd-vw(24px);
    margin-right: getd-vw(24px);
    width: getd-vw(524px);
    font-family: "VodafoneRg";
    font-size: getd-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: getd-vh(25px);
    text-align: left;
    max-height: getd-vh(500px);
    overflow: auto;
    color: #222222;
  }

  .newticket-tc-pp {
    position: absolute;
    margin-top: getd-vh(700px);
    margin-left: getd-vw(310px);
    width: getd-vw(524px) !important;
  }

  .newticket-tc-tc {
    position: absolute;
    margin-top: getd-vh(730px);
    margin-left: getd-vw(310px);
    width: getd-vw(524px) !important;
  }

  .newticket-tc-agree-cb {
    width: getd-vw(16px);
    height: getd-vh(16px);
    background-color: white;
    border-radius: 25%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .newticket-tc-agree-text {
    margin-top: getd-vh(4px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    text-align: left;
  }

  .newticket-tc-confirm {
    position: absolute;
    margin-top: getd-vh(800px);
    width: getd-vw(342px);
    padding: getd-vh(10px) 0vw getd-vh(10px) 0vw;
    border-radius: getd-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .newticket-tc-confirm:focus {
    outline: 0;
  }
  .newticket-tc-confirm:disabled {
    background-color: #ebebeb;
  }
}

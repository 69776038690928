@import "../../assets/utils";

.home-top-menu-red {
  position: fixed;
  height: get-vh(76px);
  background-color: #e60000;
  transition: background-color 200ms linear;
  border-bottom: solid 0px #ebebeb;
  z-index: 1;
}

.home-top-menu-white {
  position: fixed;  
  height: get-vh(76px);
  background-color: #ffffff;
  transition: background-color 200ms linear;
  border-bottom: solid 1px #ebebeb;
  z-index: 1;
}

.home-top-menu-left {
  height: get-vh(76px);
  text-align: left;
  padding: 0 !important;
}

.home-top-menu-logo {
  margin-top: get-vh(16px);
  margin-left: get-vw(16px);
  width: get-vh(40px);
}

.home-top-menu-midle{
  margin-top: get-vh(28px);
  height: get-vh(36px);
  text-align: center;
  padding: 0 !important;
  font-family: "VodafoneRgBd";
  font-size: get-font(22px);  
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: center;
  color: #222222;
}

.home-top-menu-right {
  height: get-vh(76px);
  text-align: right;
  padding: 0 !important;
}

.home-top-menu-notification {
  vertical-align: top;
  margin-top: get-vh(24px);
  margin-right: get-vw(16px);
  width: get-vw(24px);
}

.home-top-menu-burger {
  vertical-align: top;
  margin-top: get-vh(24px);
  margin-right: get-vw(16px);
  width: get-vw(24px);
}

@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .login-logo-splash{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: get-vh(242px);
    width: get-vw(256px);
    height: get-vw(256px);
  }
}

@media only screen and (min-width: 600px) {  
  .login-logo-splash{
    position: absolute;
    margin: auto;
    margin-top: getd-vh(242px);
    width: getd-vw(256px);
    height: getd-vw(256px);
  }
}
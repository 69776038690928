@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }
  
  .newTicketRange-labelTop {
    position: absolute;
    margin-top: get-vh(246px);
    font-family: "VodafoneRgBd";
    font-size: get-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .newTicketRange-dropdown{
    position: absolute !important;
    margin-top: get-vh(270px);
    width: get-vw(342px);
    padding: get-vh(0px) get-vw(18px);    
    border-radius: get-vw(24px);
    border: 0px solid black !important;
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    outline: none;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: left;    
  }

  .select-value-icon {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  
  .newTicketRange-labelTop {
    position: absolute;
    margin-top: getd-vh(246px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .newTicketRange-dropdown{
    position: absolute !important;
    margin-top: getd-vh(270px);
    width: getd-vw(342px);
    padding: getd-vh(0px) getd-vw(18px); 
    //padding: getd-vh(0px) getd-vw(8px) getd-vh(0px) getd-vw(8px);    
    padding-left: getd-vw(16px);
    padding-right: getd-vw(16px);
    border-radius: getd-vw(24px);
    border: 0px solid black !important;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    outline: none;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    text-align: left;    
  }

  .select-value-icon {
    display: none !important;
  }
}
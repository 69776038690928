@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }
  
  .tickets-new {
    margin-top: get-vh(24px);
  }

  .tickets-items-container{
    margin-top: get-vh(16px);   
  }
}

@media only screen and (min-width: 600px) {
  
  .tickets-new {
    margin: auto;
  }

  .tickets-main-div-full{
    transition: all 1s;
    position: absolute;
    left: getd-vw(240px);
    top: 0;
    width: getd-vw(1200px);
    height: 100vh;    
    overflow: auto;
    background-color: #ffffff;
  }

  .tickets-main-div-small{
    transition: all 1s;
    position: absolute;
    left: getd-vw(0px);
    top: 0;
    width: getd-vw(1200px);
    height: 100vh;    
    overflow: auto;
  }

  .tickets-datepicker{
    margin-top: getd-vh(30px);
  }

  .tickets-categoriespicker{
    margin-top: getd-vh(32px);
    margin-left: getd-vw(-8px);
  }

  .tickets-categoriespicker{
    margin-top: getd-vh(32px);
  }

  .tickets-tickettype{
    margin-top: getd-vh(38px);
  }

  .tickets-top-container{    
    display: flex;
    margin-top: getd-vh(46px);
    width: getd-vw(524px) !important;
  }

  .tickets-title{
    flex: 3;
    font-family: "VodafoneRgBd";
    font-size: getd-font(32px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
  }

  .tickets-items-container{
    margin-top: getd-vh(16px);   
  }

  .tickets-detail-back-container-hide{
    position: absolute;
    left:0;
    top: 0;
    width: 100vw;  
    height: 100vh;  
    transition: z-index 0.5s step-end, opacity 0.5s linear;
    z-index: 0;
    opacity: 0.0;
    background-color: #222222;
  }

  .tickets-detail-back-container-show{
    position: absolute;
    left:0;
    top: 0;
    width: 100vw;  
    height: 100vh;  
    transition: opacity 0.5s linear;
    z-index: 1;
    opacity: 0.3;
    background-color: #222222;
  }

  .tickets-detail-container-show{
    transition: all 1s;
    position: absolute;
    height: 100vh;
    right: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .tickets-detail-container-hide{
    transition: all 1s;
    position: absolute;
    height: 100vh;
    right: getd-vw(-380px);
    background-color: #ffffff;
    z-index: 2;
  }
 
}

@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .newTicketDefault-dropdown {
    position: absolute !important;
    margin-top: get-vh(110px);
    width: get-vw(342px);
    border-radius: get-vw(24px);
    border: 0px solid black !important;
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    outline: none;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    text-align: left;    
  }

  .select-value-icon {
    display: none !important;
  }

  .newTicketDefault-labelBottom{
    position: absolute;
    margin-top: get-vh(165px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }

  .newTicketDefault-subject{
    -webkit-appearance: none;
    position: absolute;
    margin-top: get-vh(110px);
    width: get-vw(305px);
    border-radius: get-vw(24px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: get-vh(0px) get-vw(16px) get-vh(0px) get-vw(16px);
    border: 0px solid black;
    resize: none;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    color: #222222;
    outline: none;
  }

  .newTicketDefault-continueButton, .newTicketDefault-sendButton {
    margin-top: get-vh(459px);
  }
}

@media only screen and (min-width: 600px) {

  .newTicketDefault-labelSubjectTop{
    position: absolute;
    margin-top: getd-vh(204px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }

  .select-value-icon {
    display: none !important;
  }

  .newTicketDefault-labelBottom{
    position: absolute;
    margin-top: getd-vh(300px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }
  
  .newTicketDefault-subject{
    -webkit-appearance: none;
    position: absolute;
    margin-top: getd-vh(228px);
    width: getd-vw(365px);
    border-radius: getd-vw(24px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: getd-vh(0px) getd-vw(16px) getd-vh(0px) getd-vw(16px);
    border: 0px solid black;
    resize: none;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    color: #222222;
    outline: none;
  }

  .newTicketDefault-continueButton, .newTicketDefault-sendButton {
    margin-top: getd-vh(788px);
  }
}


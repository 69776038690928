@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }
  
  .newTicketEmail-labelTop {
    position: absolute;
    margin-top: get-vh(246px);
    font-family: "VodafoneRgBd";
    font-size: get-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .newTicketEmail-email {
    position: absolute;
    margin-top: get-vh(270px);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    -webkit-appearance: none;
    width: get-vw(342px);
    padding: get-vh(14px) get-vw(16px) get-vh(14px) get-vw(16px);    
    border-radius: get-vw(24px);
    border: 0px solid black;
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    border: none;
    display: inline-block;
    text-shadow: 0 0 0 #222222;
  }
  
  .newTicketEmail-email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
    font-family: "VodafoneRg";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }

  .newTicketEmail-labelBottom {
    position: absolute;
    margin-top: get-vh(326px);
    font-family: "VodafoneRg";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }
  
}

@media only screen and (min-width: 600px) {
  
  .newTicketEmail-labelTop {
    position: absolute;
    margin-top: getd-vh(246px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }

  .newTicketEmail-email {
    position: absolute;
    margin-top: getd-vh(270px);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    -webkit-appearance: none;
    width: getd-vw(342px);
    padding: getd-vh(14px) getd-vw(16px) getd-vh(14px) getd-vw(16px);    
    border-radius: getd-vw(24px);
    border: 0px solid black;
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    outline: none;
    z-index: 1;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    border: none;
    display: inline-block;
    text-shadow: 0 0 0 #222222;
  }

  .newTicketEmail-email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }

  .newTicketEmail-labelBottom {
    position: absolute;
    margin-top: getd-vh(326px);
    font-family: "VodafoneRg";
    font-size: getd-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #666666;
  }
  
}

@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .login-tc-title{
    position: absolute;
    margin-top: get-vh(174px);
    margin-left: get-vw(10px);
    margin-right: get-vw(10px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .login-tc-terms {
    position: absolute;
    margin-top: get-vh(215px);
    margin-left: get-vw(24px);
    margin-right: get-vw(24px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: get-vh(20px);
    text-align: left;
    max-height: get-vh(280px);
    overflow: auto;
    color: #222222;
  }

  .login-pp-ct {
    position: absolute;
    margin-top: get-vh(516px);
  }

  .login-tc-ct {
    position: absolute;
    margin-top: get-vh(550px);
  }

  .login-pp-ct, .login-tc-ct {
    display: flex;
    margin-left: get-vw(24px);
    margin-right: get-vw(24px);
    width: get-vw(342px);
  }

  .login-tc-agree-text {    
    font-family: "VodafoneRg";
    font-size: get-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: get-vw(18px);
    text-align: left;
  }

  .login-tc-confirm {
    position: absolute;
    margin-top: get-vh(591px);
  }
}

@import "../../assets/utils";

@media only screen and (min-width: 600px) {
  .login-tc-title{
    position: absolute;
    margin-top: getd-vh(312px);
    margin-left: getd-vw(10px);
    margin-right: getd-vw(10px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .login-tc-terms {
    position: absolute;
    margin-top: getd-vh(355px);
    width: getd-vw(442px);
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: getd-vh(25px);
    text-align: left;
    max-height: getd-vh(315px);
    overflow: auto;
    color: #222222;
  }

  .login-pp-ct {
    margin-top: getd-vh(700px);
  }

  .login-tc-ct {
    margin-top: getd-vh(10px);
  }

  .login-pp-ct, .login-tc-ct {
    width: getd-vw(442px);
    display: flex;
  }

  .login-tc-agree-text {
    font-family: "VodafoneRg";
    font-size: getd-vw(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: getd-vw(18px);
    text-align: left;
  }

  .login-tc-confirm {
    margin-top: getd-vh(50px);
    margin-bottom: getd-vh(16px);
    width: getd-vw(342px);
    height: getd-vh(40px);
    padding: getd-vh(11px) 0vw getd-vh(11px) 0vw;
    border-radius: getd-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .login-tc-confirm:focus {
    outline: 0;
  }
  .login-tc-confirm:disabled {
    background-color: #ebebeb;
  }
}

.login-tc-agree-text a {
  color: #007c92;
  cursor: pointer;
}
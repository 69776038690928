@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .login-blocked-timer {
    position: absolute;
    font-family: "VodafoneRgBd";
    margin-top: get-vh(290px);
    font-size: get-vw(48px);
    font-stretch: normal;
    font-style: normal;
    line-height: get-vh(15px);
    text-align: center;
    color: #222222;
  }

  .login-blocked-resendtext {
    font-size: get-vw(18px);
    color: #e60000;
    margin-left: get-vw(82px);
    margin-right: get-vw(82px);
  }
}
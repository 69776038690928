@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .home-top-greeting {
    margin-top: get-vh(76px);
    width: 100%;
    text-align: center;
    background-color: #e60000;
    color: #ffffff;
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
  }

  .home-top-name {
    width: 100%;
    text-align: center;
    background-color: #e60000;
    color: #ffffff;
    font-family: "VodafoneRgBd";
    font-size: get-vw(24px);
  }

  .home-top-phoneNumber {
    width: 100%;
    text-align: center;
    background-color: #e60000;
    color: #ffffff;
    font-family: "VodafoneRg";
    font-size: get-vw(18px);
    letter-spacing: get-vw(2px);
  }

  .home-middle-red {
    height: get-vh(200px);
    background-color: #e60000;
  }

  .no-data-statement .home-middle-red {
    height: get-vh(65px);
  }

  .home-middle-plans {
    margin-top: get-vh(-160px);
    width: 100%;
    margin-left: get-vw(20px);
    margin-right: get-vw(20px);
    padding-top: get-vh(20px);
    padding-bottom: get-vh(20px);
    background-color: #ffffff;
    border-radius: get-vw(30px);
    box-shadow: 0 0.237vw 0.948vh 0 rgba(0, 0, 0, 0.16);
  }

  .no-data-statement .home-middle-plans {
    margin-top: get-vh(-45px);
  }

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: get-vw(200px);
    max-height: get-vh(180px);
  }

  .circle {
    stroke: #e60000;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out 2s backwards;
  }

  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
  }

  .percentage-top1 {
    fill: #000000;
    font-family: "VodafoneRgBd";
    font-size: get-vw(7px);
    text-anchor: middle;
  }

  .percentage-top2 {
    fill: #000000;
    font-family: "VodafoneRgBd";
    font-size: get-vw(4px);
    text-anchor: middle;
  }

  .percentage-bottom {
    fill: #ccc;
    font-family: "VodafoneRg";
    font-size: get-vw(2.75px);
    text-anchor: middle;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .home-middle-plans-info {
    padding: 0 get-vw(20px);
    margin: auto 0;
  }

  .home-plan-title {
    font-family: "VodafoneRgBd";
    font-size: get-vw(19px);
    text-align: left;
  }

  .home-plan-text {
    font-family: "VodafoneRgBd";
    font-size: get-vw(18px);
    color: #e60000;
    text-align: left;
  }

  .home-plan-subtext {
    font-family: "VodafoneRgBd";
    font-size: get-vw(14px);
    color: #cccccc;
    text-align: left;
  }

  .home-plan-separator {
    margin-left: get-vw(30px);
    margin-right: get-vw(30px);
    width: 100%;
    text-align: left;
  }

  .home-plan-item-container {
    display: flex;
    width: 100%;
    margin-bottom: get-vh(15px);
  }

  .home-plan-item-p1 {
    flex: 1;
  }
  .home-plan-item-p2 {
    flex: 2;
  }
  .home-plan-item-p3 {
    flex: 1;
  }

  .home-plan-item-logo {
    margin-left: get-vw(20px);
    width: get-vw(50px);
  }

  .home-plan-item-text {
    margin-top: get-vh(14px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    color: #000000;
    text-align: left;
  }

  .home-plan-item-count {
    margin-top: get-vh(14px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(14px);
    color: #cccccc;
    text-align: left;
  }

  .home-tickets-top {
    margin-top: get-vh(40px);
    width: 100%;
  }

  .home-tickets-middle {
    margin-top: get-vh(15px);
    width: 100%;
  }

  .home-tickets-title, .home-extras-title {
    flex: 3;
    margin-left: get-vw(20px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(20px);
    color: #000000;
    text-align: left;
  }

  .home-tickets-new {
    margin-right: get-vw(24px);
  }

  .home-tickets-text, .home-extras-text {
    flex: 4;
    margin-left: get-vw(20px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    color: #000000;
    text-align: left;
  }

  .home-extras-text {
    height: getd-vh(22px);
    cursor: pointer;
  }

  .home-tickets-all {
    flex: 1;
    margin-left: get-vw(20px);
    margin-right: get-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(12px);
    color: #e60000;
    text-align: right;
  }

  .home-tickets-items {
    margin-top: get-vh(15px);
  }

  .home-extras-top {
    margin-top: get-vh(40px);
    margin-bottom: get-vh(20px);
  }

  .home-extras-middle {
    padding-top: getd-vh(19px);
    height: getd-vh(40px);
  }

  .home-extras-separator {
    margin-top: get-vh(10px);
    margin-left: get-vw(20px);
    margin-right: get-vw(20px);
    width: 100%;
    text-align: left;
  }

  .home-extras-separator {
    margin-top: getd-vh(17px);
    margin-left: getd-vw(20px);
    margin-right: getd-vw(20px);
    text-align: left;
    border-top: solid 1px #ebebeb;
    width: 100%;
  }

  .home-extras-arrow {
    margin-right: get-vw(20px);
    width: get-vw(15px);
    cursor: pointer;
  }

  .main-toast {
    position: fixed;    
    margin-top: get-vh(400px);
    margin-left: get-vw(10px);
    width: get-vw(340px);
    z-index:99;
    border-radius: get-vw(19.5px);
    padding: get-vh(14px) get-vw(14px) get-vh(14px) get-vw(14px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #e60000;
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    text-align: center;
    color: #ffffff;
  }
}

@media only screen and (min-width: 600px) {  
  .support-data-left-div{
    position: absolute;
    left: 0;
    top: 0;
    width: getd-vw(240px);
    transition: all 2s;
    z-index: 0;
  } 

  .support-data-main-div-full{
    position: absolute;
    left: getd-vw(240px);
    top: 0;
    width: getd-vw(1200px);
    height: getd-vh(900px);
    overflow: auto;
    z-index: 0;
  }

  .support-data-main-div-small{
    transition: all 1s;
    position: absolute;
    left: getd-vw(0px);
    top: 0;
    width: getd-vw(1200px);
    height: 100vh;    
    overflow: auto;
  }

  .home-greeting-text{
    margin-top: getd-vh(40px);
    width: getd-vw(524px) !important;
    display: flex;
  }

  .home-greeting-text-name{
    flex: 3;
    font-family: "VodafoneRg";
    font-size: getd-font(24px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
  }

  .home-greeting-text-date{
    flex: 1;
    margin-top: getd-vh(12px);
    font-family: "VodafoneRg";
    font-size: getd-font(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #999999;
  }

  .home-middle-plans {    
    margin-top: getd-vh(40px);
    width: getd-vw(524px);    
    padding-top: getd-vh(30px);
    padding-bottom: getd-vh(30px);
    background-color: #ffffff;
    border-radius: getd-vw(30px);
    box-shadow: 0 0.237vw 0.948vh 0 rgba(0, 0, 0, 0.16);
  }

  .home-middle-plans-chart{
    width: getd-vw(234px);
    margin-left: getd-vw(32px);
  }

  .circular-chart {
    display: block;
    margin: getd-vh(20px) 0;
    max-width: getd-vw(200px);
    max-height: getd-vh(180px);
  }

  .circle {
    stroke: #e60000;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out 2s backwards;
  }

  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
  }

  .percentage-top1 {
    fill: #000000;
    font-family: "VodafoneRgBd";
    font-size: 0.4em;
    text-anchor: middle;
  }

  .percentage-top2 {
    fill: #000000;
    font-family: "VodafoneRgBd";    
    font-size: 0.2em;
    text-anchor: middle;
  }

  .percentage-bottom {
    fill: #ccc;
    font-family: "VodafoneRg";    
    text-anchor: middle;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .home-middle-info {
    margin: auto 0;
    padding: 0 getd-vw(30px);
  }

  .home-middle-number-info:not(:first-child), .home-middle-plans-info:not(:first-child) {
    margin-top: getd-vw(30px);
  }

  .home-number-title, .home-plan-title {
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    text-align: left;
  }

  .home-number-title:not(:first-child), .home-plan-title:not(:first-child) {
    margin-top: getd-vh(40px);
  }

  .home-number-text, .home-plan-text {
    margin-top: getd-vh(11px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    color: #e60000;
    text-align: left;
  }

  .home-plan-subtext {
    margin-top: getd-vh(6px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    color: #cccccc;
    text-align: left;
  }

  .home-plan-separator {
    margin-left: getd-vw(30px);
    margin-right: getd-vw(30px);
    width: 100%;
    text-align: left;
  }

  .home-plan-item-container {
    display: flex;
    width: 100%;
    margin-bottom: getd-vh(15px);
  }

  .home-plan-item-p1 {
    flex: 1;
  }
  .home-plan-item-p2 {
    flex: 2;
  }
  .home-plan-item-p3 {
    flex: 1;
  }

  .home-plan-item-logo {
    margin-left: getd-vw(20px);
    width: getd-vw(50px);
  }

  .home-plan-item-text {
    margin-top: getd-vh(14px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    color: #000000;
    text-align: left;
  }

  .home-plan-item-count {
    margin-top: getd-vh(14px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    color: #cccccc;
    text-align: left;
  }

  .home-categories{
    margin-top: getd-vh(40px);    
  }

  .home-tickets-container{
    margin-top: getd-vh(55px);
    width: getd-vw(542px) !important;
  }

  .home-tickets-top {    
    width: 100%;
  }

  .home-tickets-middle {
    margin-top: getd-vh(15px);
    width: 100%;
  }

  .home-tickets-title, .home-extras-title {
    flex: 3;
    margin-left: getd-vw(20px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    color: #000000;
    text-align: left;
  }

  .home-tickets-new {
    margin-right: getd-vw(24px);
  }

  .home-tickets-text, .home-extras-text {
    flex: 4;
    margin-left: getd-vw(20px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    color: #000000;
    text-align: left;
  }

  .home-extras-text {
    height: getd-vh(22px);
    cursor: pointer;
  }

  .home-tickets-all {
    flex: 1;
    margin-left: getd-vw(20px);
    margin-right: getd-vw(24px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(12px);
    color: #e60000;
    text-align: right;
    cursor: pointer;
  }

  .home-tickets-items {
    margin-top: getd-vh(15px);
  }

  .home-extras-container{
    margin-top: getd-vh(55px);
    width: getd-vw(542px) !important;
  }

  .home-extras-top {
    margin-top: getd-vh(40px);
    margin-bottom: getd-vh(20px);
  }

  .home-extras-middle {
    padding-top: getd-vh(19px);
    height: getd-vh(40px);
  }

  .home-extras-separator {
    margin-top: getd-vh(17px);
    margin-left: getd-vw(20px);
    margin-right: getd-vw(20px);
    text-align: left;
    border-top: solid 1px #ebebeb;
    width: 100%;
  }

  .home-extras-arrow {
    margin-right: getd-vw(20px);
    width: getd-vw(24px);
    height: getd-vh(24px);
    cursor: pointer;
  }

  .main-toast {
    position: absolute;    
    margin-top: getd-vh(720px);
    margin-left: getd-vw(655px);
    width: getd-vw(340px);
    z-index:99;
    border-radius: get-vw(19.5px);
    padding: getd-vh(14px) getd-vw(14px) getd-vh(14px) getd-vw(14px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #e60000;
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    text-align: center;
    color: #ffffff;
  }
}
@import "./../../assets/utils";

@media only screen and (max-width: 600px) {
  

  
}

@media only screen and (min-width: 600px) {
  
  .menu-main-div{
    width: getd-vw(240px) !important;    
    height: 100vh;
    margin-left: 0px !important;
    //background-color: aqua;
    background-image: radial-gradient(farthest-corner at 0px 0px , #aa000055 0%, #FFFFFF 65%);
    border-right: solid getd-vw(1px) #f4f4f4;
  }

  .menu-main-logo-container{
    position: absolute;
    margin-top: getd-vh(40px);  
    width: getd-vw(240px) !important;
  }

  .menu-main-logo-container > * {
    cursor: pointer;
  }

  .menu-main-logo{  
    margin-left: getd-vw(32px);
    width: getd-vw(48px);
  }
  .menu-main-logo-text{
    margin: auto getd-vw(12px);
    width: getd-vw(100px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.71px);
    color: #222222;
    text-align: left;
  }

  .menu-main-user-container{
    position: absolute;
    margin-top: getd-vh(145px);  
    width: getd-vw(240px) !important;
  }

  .menu-main-user-logo-container{  
    margin-left: getd-vw(32px);
    width: getd-vw(20px);
    height: getd-vw(20px);
    background-color: #ffffff;
    padding-top: getd-vh(3px);
    padding-left: getd-vw(6px);
    padding-bottom: getd-vh(6px);
    padding-right: getd-vw(6px);
    border-radius: getd-vw(40px);
  }

  .menu-main-user-logo{  
    position: relative;
    top: getd-vh(0px);
    height: getd-vw(19px);
    width: getd-vw(19px);
  }
  .menu-main-user-text{  
    margin-top: getd-vh(5px);
    margin-left: getd-vw(6px); 
    font-family: "VodafoneRg";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    color: #222222;
    text-align: left;
  }

  .menu-main-links-container{
    position: absolute;
    margin-top: getd-vh(225px);  
    width: getd-vw(240px) !important;
  }
  .menu-main-links-item{
    margin-top: getd-vh(55px);
  }
  .menu-main-links-item-logo{
    margin-left: getd-vw(32px);
    margin-top: getd-vh(5px);
    height: getd-vh(16px);
    width: getd-vw(16px);
  }
  .menu-main-links-item-text{    
    margin-left: getd-vw(9px); 
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.71px);
    color: #222222;
    text-align: left;
    cursor: pointer;
  }  
  .menu-main-links-item-text-selected{
    margin-left: getd-vw(9px); 
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.71px);
    color: #e60000;
    text-align: left;
    cursor: pointer;
  }

  .menu-main-language-container{
    position: absolute;
    margin-top: getd-vh(600px);
    margin-left: getd-vw(20px);
    width: fit-content  !important;
    border-radius: 16px;
    //background-color: #007c9219;
    padding: getd-vh(7px) getd-vw(12px) getd-vh(7px) getd-vw(10px);
    cursor: pointer;
  }

  .menu-main-language {
    font-family: "VodafoneRg";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.64px);
    color: #007c92;

    /* styling */
    border: 0;
    width: getd-vw(105px);
    padding: 0;

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url(../../assets/icons-chevron-down-fill.svg);
    background-position: center right;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  
  .menu-main-language option {
    width: getd-vw(70.4px);
  }

  .menu-main-logout-container{
    position: absolute;
    margin-top: getd-vh(678px);
    margin-left: getd-vw(40px);
    width: fit-content  !important;
    border-radius: 16px;
    background-color: #007c9219;
    padding: getd-vh(7px) getd-vw(12px) getd-vh(7px) getd-vw(10px);
    cursor: pointer;
  }
  .menu-main-logout{    
    width: getd-vw(16px);
  }
  .menu-main-logout-text{
    margin-top: getd-vh(2px);
    margin-left: getd-vw(8px);    
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #007c92;
    text-align: left;    
  }
  .menu-main-pth-container{
    position: absolute;
    margin-top: getd-vh(734px);
    margin-left: getd-vw(40px);
    width: getd-vw(200px) !important;   
  }
  .menu-main-pth-text{
    margin-top: getd-vh(16px);   
    width: getd-vw(200px) !important;
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    color: #222222;
    text-align: left;
  }

  
  .home-menu-logout-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000aa;
    z-index: 1;
  }
  .home-logout-container{
    position: absolute;
    margin-top: getd-vh(283px);
    margin-left: getd-vw(549px);
    width: getd-vw(326px) !important;
    height: getd-vh(278px);
    border-radius: getd-vw(24px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  .home-logout-title{
    position: absolute;
    margin-top: getd-vh(32px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(24px);  
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing:getd-vw(0.85px);
    text-align: center;
    color: #222222;
  }
  .home-logout-text{
    position: absolute;
    margin-top: getd-vh(90px);
    font-family: "VodafoneRg";
    font-size: getd-font(16px);  
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  .home-logout-button-logout{
    position: absolute;
    margin-top: getd-vh(158px);
    margin-left: getd-vw(24px);
    width: getd-vw(278px) !important;
    height: getd-vh(40px);
    padding: getd-vh(11px) 0vw getd-vh(11px) 0vw;
    border-radius: getd-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .home-logout-button-logout:focus {
    outline: 0;
  }
  .home-logout-button-logout:disabled {
    background-color: #ebebeb;
  }
  
  .home-logout-button-cancel{
    position: absolute;
    margin-top: getd-vh(214px);
    margin-left: getd-vw(24px);
    width: getd-vw(278px) !important;
    height: getd-vh(40px);
    padding: getd-vh(11px) 0vw getd-vh(11px) 0vw;
    border-radius: getd-vh(20px);
    border: getd-vw(1px) solid #222222;
    background-color: #ffffff;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
    cursor: pointer;
  }
  .home-logout-button-cancel:focus {
    outline: 0;
  }
  .home-logout-button-cancel:disabled {
    background-color: #ebebeb;
  }   
}

@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }

  .newticket-top-menu-left {
    position: absolute;
    height: get-vh(76px);
    width: 100vw;
    text-align: left;
    padding: 0 !important;
  }
  
  .newticket-top-menu-logo {
    margin-top: get-vh(16px);
    margin-left: get-vw(16px);
    width: get-vh(40px);
  }
  
  .newticket-top-menu-midle{
    position: absolute;
    margin-top: get-vh(28px);
    height: get-vh(36px);
    width: 100vw;
    text-align: center;
    padding: 0 !important;
    font-family: "VodafoneRgBd";
    font-size: get-font(22px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.71px;
    text-align: center;
    color: #222222;
  }
  
  .newticket-top-menu-right {
    position: absolute;
    height: get-vh(76px);
    width: 100vw;
    text-align: right;
    padding: 0 !important;
  }

  .newticket-top-menu-close{
    vertical-align: top;
    margin-top: get-vh(24px);
    margin-right: get-vw(16px);
    width: get-vw(24px);
  }
  
  
  .newticket-top-menu-back{
    position: absolute;
    margin-top: get-vh(50px);  
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: get-vw(5px) get-vw(5px);
    border-radius: get-vw(40px);
    cursor: pointer;
  }

  .newticket-text{
    position: absolute;
    margin-top: get-vh(130px);
    max-width: get-vw(320px);
    margin-left: get-vw(35px);
    margin-right: get-vw(35px);
    font-family: "VodafoneRgBd";
    font-size: get-font(24px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: get-vw(0.85px);
    color: #222222;
  }
  
  .newticket-subText{
    position: absolute;
    max-width: get-vw(320px);
    margin-top: get-vh(173px);     
    margin-left: get-vw(35px);
    margin-right: get-vw(35px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-summary{
    -webkit-appearance: none;
    position: absolute;
    margin-top: get-vh(214px);
    width: get-vw(300px);
    height: get-vh(156px);
    border-radius: get-vw(24px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: get-vh(14px) get-vw(20px);
    border: 0px solid black;
    resize: none;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    color: #222222;
    outline: none;
  }

  .newticket-summary::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
    font-family: "VodafoneRg";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }

  .newticket-summary-count{    
    position: absolute;
    margin-top: get-vh(411px);
    width: get-vw(332px);
    text-align: right;
    font-family: "VodafoneRgBd";
    font-size: get-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    color: #999;
    outline: none;
  }

  .newticket-success-img{
    position: absolute; 
    margin-top: get-vh(118px);
    width: get-vw(104px);
    height: get-vh(104px);
  }

  .newticket-success-text{
    position: absolute; 
    margin-top: get-vh(254px);
    margin-left: get-vw(35px);
    margin-right: get-vw(35px);
    font-family: "VodafoneRgBd";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-success-subtext{
    position: absolute; 
    margin-top: get-vh(318px);
    margin-left: get-vw(35px);
    margin-right: get-vw(35px);
    font-family: "VodafoneRg";
    font-size: get-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-type-container {
    position: absolute;
    margin-top: get-vh(230px);
  }

  .newticket-type {
    margin-top: get-vh(24px);
    width: get-vw(342px);
    padding: get-vh(11px) 0vw get-vh(11px) 0vw;
    border-radius: get-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .newticket-type:focus {
    outline: 0;
  }
  .newticket-type:disabled {
    background-color: #ebebeb;
  }

  .newTicket-continueButton {
    position: absolute; 
    margin-top: get-vh(383px);
    width: get-vw(342px);
    padding: get-vh(11px) 0vw get-vh(11px) 0vw;
    border-radius: get-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }
  .newTicket-continueButton:focus {
    outline: 0;
  }
  .newTicket-continueButton:disabled {
    background-color: #ebebeb;
    color: #afafaf;
  }

  .newticket-confirm-container{   
    margin-top: get-vh(191px);
    width: get-vw(342px);
    height: get-vh(152px);
    border-radius: get-vw(24px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    height: fit-content;
    padding-bottom: get-vh(24px);
  }
  .newticket-confirm-label{
    margin: get-vh(26px) get-vw(24px) get-vh(0px) get-vw(24px);
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    color: #999999;
  }
  .newticket-confirm-value{
    margin: get-vh(2px) get-vw(24px);
    width: auto !important;
    max-height: get-vh(156px);    
    overflow-y: auto;
    word-break:break-all;
    text-align: left;
    font-family: "VodafoneRg";
    font-size: get-font(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.64px);
    color: #222222;
  }

  .newTicket-confirm-sendButton{   
    margin-top: get-vh(40px);
  }

  .newTicketDefault-continueButton {
    margin-top: get-vh(459px);
  }

  .newTicket-confirm-continueButton:disabled {
    background-color: #ebebeb;
  }

  .newticket-confirm-continueButton-bottom{    
      margin-top: get-vh(95px);
      font-family: "VodafoneRgBd";
      font-size: get-font(14px);    
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #007c92;
      cursor: pointer;
  }
}


@media only screen and (min-width: 600px) {

  .newticket-top-menu-left {
    position: absolute;
    height: getd-vh(76px);
    width: 100vw;
    text-align: left;
    padding: 0 !important;
  }
  
  .newticket-top-menu-logo {
    margin-top: getd-vh(16px);
    margin-left: getd-vw(16px);
    width: getd-vh(40px);
  }
  
  .newticket-top-menu-midle{
    position: absolute;
    margin-top: getd-vh(28px);
    height: getd-vh(36px);
    width: 100vw;
    text-align: center;
    padding: 0 !important;
    font-family: "VodafoneRgBd";
    font-size: getd-font(22px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.71px;
    text-align: center;
    color: #222222;
  }
  
  .newticket-top-menu-right {
    position: absolute;
    height: getd-vh(76px);
    width: 100vw;
    text-align: right;
    padding: 0 !important;
  }

  .newticket-top-menu-close{
    vertical-align: top;
    margin-top: getd-vh(24px);
    margin-right: getd-vw(16px);
    width: getd-vw(24px);    
  }

  .newticket-top-menu-back{
    position: absolute;
    margin-top: getd-vh(50px);
    margin-left: getd-vw(-500px);    
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: getd-vw(5px) getd-vw(5px);
    border-radius: getd-vw(40px);
    cursor: pointer;
  }
  
  .newticket-text{
    position: absolute;
    margin-top: getd-vh(51px);
    max-width: getd-vw(320px);
    margin-left: getd-vw(35px);
    margin-right: getd-vw(35px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(24px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: getd-vw(0.85px);
    color: #222222;
  }
  
  .newticket-subText{
    position: absolute;
    width: getd-vw(600px);
    margin-top: getd-vh(140px);
    font-family: "VodafoneRg";
    font-size: getd-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }
  
  .newticket-label-message-top {
    position: absolute;
    margin-top: getd-vh(360px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }

  .newticket-summary{
    -webkit-appearance: none;
    position: absolute;
    margin-top: getd-vh(384px);
    width: getd-vw(368px);
    height: getd-vh(188px);
    border-radius: getd-vw(24px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    padding: getd-vh(14px) getd-vw(16px) getd-vh(14px) getd-vw(16px);
    border: 0px solid black;
    resize: none;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    color: #222222;
    outline: none;
  }

  .newticket-summary::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }

  .newticket-summary-count{
    position: absolute;
    margin-top: getd-vh(615px);
    width: getd-vw(400px);
    text-align: right;
    font-family: "VodafoneRgBd";
    font-size: getd-font(14px);    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    color: #999;
    outline: none;
  }

  .newticket-success-img{
    position: absolute; 
    margin-top: getd-vh(118px);
    width: getd-vw(104px);
    height: getd-vh(104px);
  }

  .newticket-success-text{
    position: absolute; 
    margin-top: getd-vh(254px);
    margin-left: getd-vw(35px);
    margin-right: getd-vw(35px);
    font-family: "VodafoneRgBd";
    font-size: getd-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-success-subtext{
    position: absolute; 
    margin-top: getd-vh(318px);
    margin-left: getd-vw(35px);
    margin-right: getd-vw(35px);
    font-family: "VodafoneRg";
    font-size: getd-vw(16px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #222222;
  }

  .newticket-type-container {
    position: absolute;
    margin-top: getd-vh(230px);
  }

  .newticket-type {
    margin-top: getd-vh(24px);
    width: getd-vw(342px);
    padding: getd-vh(11px) 0vw getd-vh(11px) 0vw;
    border-radius: getd-vh(20px);
    border: 0px solid black;
    background-color: #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .newTicket-continueButton,
  .newTicket-sendButton,
  .newTicket-confirm-continueButton,
  .newTicket-confirm-sendButton {
    margin-top: getd-vh(788px);
  }

  .newticket-confirm-container{
    position: absolute;
    margin-top: getd-vh(250px);
    width: getd-vw(524px);
    height: getd-vh(152px);
    border-radius: getd-vw(24px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    height: fit-content;
    padding-bottom: getd-vh(24px);
  }
  .newticket-confirm-label{
    margin: getd-vh(26px) getd-vw(24px )getd-vh(0px) getd-vw(24px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    color: #999999;
  }
  .newticket-confirm-value{
    margin: getd-vh(2px) getd-vw(24px);
    width: auto !important;
    max-height: getd-vh(350px);
    overflow-y: auto;
    word-break:break-all;
    text-align: left;
    font-family: "VodafoneRg";
    font-size: getd-font(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    color: #222222;
  }

  .newTicket-confirm-continueButton{
    margin-top: getd-vh(788px);
  }
  .newTicket-confirm-continueButton:disabled {
    background-color: #ebebeb;
  }

  .newticket-confirm-continueButton-bottom{  
      margin-top: getd-vh(804px);  
      font-family: "VodafoneRgBd";
      font-size: getd-font(14px);    
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #007c92;
  }
}
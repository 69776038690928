@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .data-categories-container{
    white-space: nowrap;
    display: inline-flex;
    width: 100vw;
    overflow: auto;
    margin-top: get-vh(10px);   
    padding-bottom: get-vh(10px);
  }
  .data-categories-container-item-1{ 
    margin-left: get-vw(24px);
  }
  .data-categories-container-item{
    margin-top: get-vh(6px);
    margin-right: get-vw(8px);
    border-radius: get-vw(16px);
    background-color: #9999994c;    
    padding: get-vh(7px) get-vw(15px) get-vh(9px) get-vw(16px);
    
  }
  .data-categories-container-item-selected{
    margin-top: get-vh(6px);
    margin-right: get-vw(8px);
    border-radius: get-vw(16px);
    box-shadow: 0 get-vw(2px) get-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: get-vh(7px) get-vw(15px) get-vh(9px) get-vw(16px);  
  }
  .data-categories-container-item-text{    
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.49px);
    text-align: center;
    color: #222222;
  }

}

@media only screen and (min-width: 600px) {
  .data-categories-container{
    margin-top: getd-vh(6px);
    white-space: nowrap;
    display: inline-flex;
    width: getd-vw(524px);
    padding-top: getd-vh(2px);
    padding-bottom: getd-vh(10px);
    overflow: auto;    
  }
  .data-categories-container-item-1 {
    margin-left: getd-vw(8px);
  
  }

  .data-categories-container-item {    
    margin-right: getd-vw(8px);
    border-radius: getd-vw(16px);
    background-color: #9999994c;    
    padding: getd-vh(10px) getd-vw(15px) getd-vh(6px) getd-vw(16px);
    cursor: pointer;  
  }
  .data-categories-container-item-selected {    
    margin-right: getd-vw(8px);
    border-radius: getd-vw(16px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: getd-vh(10px) getd-vw(15px) getd-vh(6px) getd-vw(16px);
    cursor: pointer;
  }

 

  .data-categories-container-item-text {    
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    text-align: center;
    color: #222222;
  }
}
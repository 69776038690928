body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:hover {
  text-decoration: underline;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
  
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}
  
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;  
}
  
::-webkit-scrollbar-thumb:hover {
  background: #999;  
}

@media only screen and (min-width: 600px) {
  body {
    overflow-x: hidden;
  }
}

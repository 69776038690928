@import "../assets/utils";


@media only screen and (max-width: 600px) {
  .empty-data {
    margin-left: auto;
    margin-right: auto;
    font-family: "VodafoneRg";
    font-size: get-font(14px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-font(0.49px);
    text-align: center;
    color: #999;
  }
  
  .empty-data .icon-empty-box{
    width: get-vw(140px);
    height: get-vh(140px);
    margin: auto;
    padding: get-vh(18px) get-vh(17px) get-vh(17px) get-vh(18px);
    padding: 18px 17px 17px 18px;
    object-fit: contain;
  }
}

@media only screen and (min-width: 600px) {
  .empty-data {
    margin-left: auto;
    margin-right: auto;
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-font(0.49px);
    text-align: center;
    color: #999;
  }
  
  .empty-data .icon-empty-box{
    width: getd-vw(140px);
    height: getd-vh(140px);
    margin: auto;
    padding: getd-vh(18px) getd-vh(17px) getd-vh(17px) getd-vh(18px);
    object-fit: contain;
  }
}

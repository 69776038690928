@import "../../assets/utils";

@media only screen and (max-width: 600px) {

}

@media only screen and (min-width: 600px) {

  .ticket-type-container .content-card-item-logo.loading-placeholder {
    margin-top: getd-vh(1px);
    height: getd-vh(28px);
  }

  .ticket-type-container .content-card-item-label.loading-placeholder {
    float: left;
    padding-right: getd-vw(200px);
    margin-top: getd-vh(4px);
    height: getd-vh(21px);
  }

  .ticket-type-container .content-card-item-action.loading-placeholder {
    background-color: #999;
  }
}
@import "../../assets/utils";

@media only screen and (max-width: 600px) {

  .ticket-type-container{
    margin: get-vh(27px) get-vw(10px) get-vh(0px) get-vw(0px);
    white-space: nowrap;
    display: inline-flex;
    width: 100vw;
    overflow: auto; 
  }

  .ticket-type-container.btn-group {
    margin-left: get-vw(10px);
  }

  @supports (-webkit-touch-callout: none) {
    .ticket-type-container{
      margin: get-vh(27px) get-vw(10px) get-vh(0px) get-vw(-10px);      
    }
  }

  .ticket-type-item-selected{
    margin-right: get-vw(14px);
    background-color: #ffffff;
    border: 0px solid black;
    border-bottom: 2px solid #e60000;
    font-family: "VodafoneRgBd";
    font-size: get-font(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    text-align: center;
    color: #222222;
    padding: 0;
    outline: 0;
  }

  .ticket-type-item{
    margin-right: get-vw(14px);
    background-color: #ffffff;
    border: 0px solid black;
    font-family: "VodafoneRg";
    font-size: get-font(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: get-vw(0.56px);
    text-align: center;
    color: #999999;
    padding: 0;
    outline: 0;
  }
}

@media only screen and (min-width: 600px) {
  .ticket-type-container{   
    white-space: nowrap;
    display: inline-flex;
    width: getd-vw(524px) !important;
    padding-top: getd-vh(5px);
    overflow: auto; 
  }

  .ticket-type-item-selected{
    margin-right: getd-vw(14px);
    background-color: #ffffff;
    border: 0px solid black;
    border-bottom: 2px solid #e60000;
    font-family: "VodafoneRgBd";
    font-size: getd-font(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    text-align: center;
    color: #222222;
    padding: 0;
    cursor: pointer;
  }

  .ticket-type-item{
    margin-right: getd-vw(14px);
    background-color: #ffffff;
    border: 0px solid black;
    font-family: "VodafoneRg";
    font-size: getd-font(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.56px);
    text-align: center;
    color: #999999;
    padding: 0;
    cursor: pointer;
  }
}
@import "../../assets/utils.scss";

@media only screen and (max-width: 600px) {
 
  
}


@media only screen and (min-width: 600px) {

  .support-ticket-detail-close-container{
    position: fixed;
    margin-top: getd-vh(48px);
    margin-left: getd-vw(-14px);
    width: getd-vw(32px);
    height: getd-vw(32px);
    border-radius: getd-vw(32px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    cursor: pointer;
  }
  .support-ticket-detail-close{    
    margin-top: getd-vh(12px);
    width: getd-vw(16px);
    height: getd-vh(16px);    
  }

  .support-ticket-detail-logo{
    width: getd-vw(48px);
    height: getd-vh(48px);
    margin-top: getd-vh(44px);    
    margin-left: getd-vw(30px);    
  }
  .support-ticket-detail-title{
    width: getd-vw(300px) !important;
    margin-top: getd-vh(48px);
    padding-bottom: getd-vh(10px);
    margin-left: getd-vw(40px);
    font-family: "VodafoneRgBd";
    font-size: getd-font(24px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
  }

  .support-ticket-detail-param{
    margin-top: getd-vh(20px);
    margin-left: getd-vw(40px);
    font-family: "VodafoneRg";
    font-size: getd-font(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.49px);
    color: #999999;
  }

  .support-ticket-detail-value{  
    width: getd-vw(300px) !important;
    margin-left: getd-vw(40px);
    font-family: "VodafoneRg";
    font-size: getd-font(18px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: getd-vw(0.64px);
    text-align: left;
    color: #222222;
  }
  


}



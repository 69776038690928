@import "../../assets/utils";

@media only screen and (max-width: 600px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    display: none;
  }


  .login-language-input {
    position: absolute;
    margin-top: get-vh(296px);
    width: get-vw(342px);
    padding: 1.422vh 4.103vw;
    border-radius: 2.844vh;
    box-shadow: 0 0.513vw 0.948vh 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    font-family: "VodafoneRgBd";
    font-size: get-vw(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.126vw;
    color: #222222;
    outline: 0;
    border: 0px solid black;

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }

}

@media only screen and (min-width: 600px) {
 
  .login-language-input {
    position: absolute;
    margin-top: getd-vh(491px);
    width: getd-vw(342px);
    height: getd-vh(58px);
    border-radius: getd-vw(24px);
    box-shadow: 0 getd-vw(2px) getd-vh(8px) 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    font-family: "VodafoneRgBd";
    font-size: getd-font(20px);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.126vw;
    color: #222222;
    outline: 0;
    border: 0px solid black;

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }

}